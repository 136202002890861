.terms-clube-amigo__title{
  text-align: center;
  text-decoration: underline;
  font-weight: 800;
  font-size: 1.25rem;
}

.terms-clube-amigo__subtitle{
  font-weight: 800;
  font-size: 1.15rem;
}

.terms-clube-amigo__sub-items{
  font-weight: initial;
  font-size: 1rem;
}


.terms-clube-amigo ol {
  counter-reset: item;
  padding-left: 10px;
}

.terms-clube-amigo ol > li {
  display: block;
  margin: 1rem 0;
}

.terms-clube-amigo ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin-right: 1rem;
}
