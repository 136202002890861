/* margin */

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.m-5 {
  margin: 25px !important;
}

.m-6 {
  margin: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.mt-4 {
  margin-top: 20px !important;
}

.mt-5 {
  margin-top: 25px !important;
}

.mt-6 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.mb-5 {
  margin-bottom: 25px !important;
}

.mb-6 {
  margin-bottom: 30px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.ml-4 {
  margin-left: 20px !important;
}

.ml-5 {
  margin-left: 25px !important;
}

.ml-6 {
  margin-left: 30px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 5px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 15px !important;
}

.mr-4 {
  margin-right: 20px !important;
}

.mr-5 {
  margin-right: 25px !important;
}

.mr-6 {
  margin-right: 30px !important;
}

.mx-0 {
  margin: 0 0px !important;
}

.mx-1 {
  margin: 0 5px !important;
}

.mx-2 {
  margin: 0 10px !important;
}

.mx-3 {
  margin: 0 15px !important;
}

.mx-4 {
  margin: 0 20px !important;
}

.mx-5 {
  margin: 0 25px !important;
}

.mx-6 {
  margin: 0 30px !important;
}

.my-0 {
  margin: 0px 0 !important;
}

.my-1 {
  margin: 5px 0 !important;
}

.my-2 {
  margin: 10px 0 !important;
}

.my-3 {
  margin: 15px 0 !important;
}

.my-4 {
  margin: 20px 0 !important;
}

.my-5 {
  margin: 25px 0 !important;
}

.my-6 {
  margin: 30px 0 !important;
}

@media screen and (max-width: 768px) {

  .m-md-0 {
    margin: 0px !important;
  }

  .m-md-1 {
    margin: 5px !important;
  }

  .m-md-2 {
    margin: 10px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .m-md-4 {
    margin: 20px !important;
  }

  .m-md-5 {
    margin: 25px !important;
  }

  .m-md-6 {
    margin: 30px !important;
  }

  .mt-md-0 {
    margin-top: 0px !important;
  }

  .mt-md-1 {
    margin-top: 5px !important;
  }

  .mt-md-2 {
    margin-top: 10px !important;
  }

  .mt-md-3 {
    margin-top: 15px !important;
  }

  .mt-md-4 {
    margin-top: 20px !important;
  }

  .mt-md-5 {
    margin-top: 25px !important;
  }

  .mt-md-6 {
    margin-top: 30px !important;
  }

  .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-1 {
    margin-bottom: 5px !important;
  }

  .mb-md-2 {
    margin-bottom: 10px !important;
  }

  .mb-md-3 {
    margin-bottom: 15px !important;
  }

  .mb-md-4 {
    margin-bottom: 20px !important;
  }

  .mb-md-5 {
    margin-bottom: 25px !important;
  }

  .mb-md-6 {
    margin-bottom: 30px !important;
  }

  .ml-md-0 {
    margin-left: 0px !important;
  }

  .ml-md-1 {
    margin-left: 5px !important;
  }

  .ml-md-2 {
    margin-left: 10px !important;
  }

  .ml-md-3 {
    margin-left: 15px !important;
  }

  .ml-md-4 {
    margin-left: 20px !important;
  }

  .ml-md-5 {
    margin-left: 25px !important;
  }

  .ml-md-6 {
    margin-left: 30px !important;
  }

  .mr-md-0 {
    margin-right: 0px !important;
  }

  .mr-md-1 {
    margin-right: 5px !important;
  }

  .mr-md-2 {
    margin-right: 10px !important;
  }

  .mr-md-3 {
    margin-right: 15px !important;
  }

  .mr-md-4 {
    margin-right: 20px !important;
  }

  .mr-md-5 {
    margin-right: 25px !important;
  }

  .mr-md-6 {
    margin-right: 30px !important;
  }

  .mx-md-0 {
    margin: 0 0px !important;
  }

  .mx-md-1 {
    margin: 0 5px !important;
  }

  .mx-md-2 {
    margin: 0 10px !important;
  }

  .mx-md-3 {
    margin: 0 15px !important;
  }

  .mx-md-4 {
    margin: 0 20px !important;
  }

  .mx-md-5 {
    margin: 0 25px !important;
  }

  .mx-md-6 {
    margin: 0 30px !important;
  }

  .my-md-0 {
    margin: 0px 0 !important;
  }

  .my-md-1 {
    margin: 5px 0 !important;
  }

  .my-md-2 {
    margin: 10px 0 !important;
  }

  .my-md-3 {
    margin: 15px 0 !important;
  }

  .my-md-4 {
    margin: 20px 0 !important;
  }

  .my-md-5 {
    margin: 25px 0 !important;
  }

  .my-md-6 {
    margin: 30px 0 !important;
  }
}

/* padding */

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.p-6 {
  padding: 30px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 15px !important;
}

.pt-4 {
  padding-top: 20px !important;
}

.pt-5 {
  padding-top: 25px !important;
}

.pt-6 {
  padding-top: 30px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pb-2 {
  padding-bottom: 10px !important;
}

.pb-3 {
  padding-bottom: 15px !important;
}

.pb-4 {
  padding-bottom: 20px !important;
}

.pb-5 {
  padding-bottom: 25px !important;
}

.pb-6 {
  padding-bottom: 30px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.pl-3 {
  padding-left: 15px !important;
}

.pl-4 {
  padding-left: 20px !important;
}

.pl-5 {
  padding-left: 25px !important;
}

.pl-6 {
  padding-left: 30px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.pr-4 {
  padding-right: 20px !important;
}

.pr-5 {
  padding-right: 25px !important;
}

.pr-6 {
  padding-right: 30px !important;
}

.px-0 {
  padding: 0 0px !important;
}

.px-1 {
  padding: 0 5px !important;
}

.px-2 {
  padding: 0 10px !important;
}

.px-3 {
  padding: 0 15px !important;
}

.px-4 {
  padding: 0 20px !important;
}

.px-5 {
  padding: 0 25px !important;
}

.px-6 {
  padding: 0 30px !important;
}

.py-0 {
  padding: 0px 0 !important;
}

.py-1 {
  padding: 5px 0 !important;
}

.py-2 {
  padding: 10px 0 !important;
}

.py-3 {
  padding: 15px 0 !important;
}

.py-4 {
  padding: 20px 0 !important;
}

.py-5 {
  padding: 25px 0 !important;
}

.py-6 {
  padding: 30px 0 !important;
}

@media screen and (max-width: 768px) {
  .p-md-0 {
    padding: 0px !important;
  }

  .p-md-1 {
    padding: 5px !important;
  }

  .p-md-2 {
    padding: 10px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .p-md-4 {
    padding: 20px !important;
  }

  .p-md-5 {
    padding: 25px !important;
  }

  .p-md-6 {
    padding: 30px !important;
  }

  .pt-md-0 {
    padding-top: 0px !important;
  }

  .pt-md-1 {
    padding-top: 5px !important;
  }

  .pt-md-2 {
    padding-top: 10px !important;
  }

  .pt-md-3 {
    padding-top: 15px !important;
  }

  .pt-md-4 {
    padding-top: 20px !important;
  }

  .pt-md-5 {
    padding-top: 25px !important;
  }

  .pt-md-6 {
    padding-top: 30px !important;
  }

  .pb-md-0 {
    padding-bottom: 0px !important;
  }

  .pb-md-1 {
    padding-bottom: 5px !important;
  }

  .pb-md-2 {
    padding-bottom: 10px !important;
  }

  .pb-md-3 {
    padding-bottom: 15px !important;
  }

  .pb-md-4 {
    padding-bottom: 20px !important;
  }

  .pb-md-5 {
    padding-bottom: 25px !important;
  }

  .pb-md-6 {
    padding-bottom: 30px !important;
  }

  .pl-md-0 {
    padding-left: 0px !important;
  }

  .pl-md-1 {
    padding-left: 5px !important;
  }

  .pl-md-2 {
    padding-left: 10px !important;
  }

  .pl-md-3 {
    padding-left: 15px !important;
  }

  .pl-md-4 {
    padding-left: 20px !important;
  }

  .pl-md-5 {
    padding-left: 25px !important;
  }

  .pl-md-6 {
    padding-left: 30px !important;
  }

  .pr-md-0 {
    padding-right: 0px !important;
  }

  .pr-md-1 {
    padding-right: 5px !important;
  }

  .pr-md-2 {
    padding-right: 10px !important;
  }

  .pr-md-3 {
    padding-right: 15px !important;
  }

  .pr-md-4 {
    padding-right: 20px !important;
  }

  .pr-md-5 {
    padding-right: 25px !important;
  }

  .pr-md-6 {
    padding-right: 30px !important;
  }

  .px-md-0 {
    padding: 0 0px !important;
  }

  .px-md-1 {
    padding: 0 5px !important;
  }

  .px-md-2 {
    padding: 0 10px !important;
  }

  .px-md-3 {
    padding: 0 15px !important;
  }

  .px-md-4 {
    padding: 0 20px !important;
  }

  .px-md-5 {
    padding: 0 25px !important;
  }

  .px-md-6 {
    padding: 0 30px !important;
  }

  .py-md-0 {
    padding: 0px 0 !important;
  }

  .py-md-1 {
    padding: 5px 0 !important;
  }

  .py-md-2 {
    padding: 10px 0 !important;
  }

  .py-md-3 {
    padding: 15px 0 !important;
  }

  .py-md-4 {
    padding: 20px 0 !important;
  }

  .py-md-5 {
    padding: 25px 0 !important;
  }

  .py-md-6 {
    padding: 30px 0 !important;
  }
}