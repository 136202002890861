.details-modal {
  padding: 0!important;
}

.details-modal h6:not(:first-child){
  margin-top: 1em;
}

.details-modal h6{
  font-size: 16px;
  margin-bottom: .25em;
}

.details-modal p {
  margin: 0!important;
}

.details-modal .modal-dialog {
  margin: auto;
  top: 50%;
  transform: translateY(-50%)!important;
}

.details-modal .modal-body {
  max-height: 70vh;
  overflow: auto;
}

.details-modal__section {
  margin-bottom: 1em;
}

.details-modal__section--minus-image {
  max-width: calc(100% - 150px);
}

@media (max-width: 550px) {
  .details-modal__section--minus-image {
    max-width: calc(100% - 100px);
  }
}

.details-modal__item-title {
  font-weight: 500;
}

.details-modal__additional-text {
  font-size: .9em;
  font-style: italic;
  display: block;
  margin-top: .5em;
}

.details-modal .center {
  text-align: center;
}

.details-modal__step-badge {
  background: rgb(211, 211, 211);
  padding: .25em;
  border-radius: 4px;
  font-weight: 600;
  margin-bottom: .5em;
}

.details-modal__step-badge--green {
  background: #5cb85c;
  color: white;
}

.indexedStep {
  color: white;
  width: 40px;
  height: 40px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep__description-wrapper {
  position: relative;
}

.indexedStep__description {
  position: absolute;
  color: black;
  top: 2.5em;
  right: 0;
  left: 8px;
  transform: translateX(-25%);
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

.indexedStep__icon {
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
  transform: translateX(-25%);
}

.RSPBprogressBar .RSPBprogression {
  background: var(--primary-color)!important;
}

.indexedStep.accomplished {
  background-color: var(--primary-color);
}

.details-modal__progress-bar {
  padding: 6em 3em 4.5em 3em;
}

.details-modal__product-image-wrapper {
  position: relative;
  width: 100%;
}

.details-modal__product-image {
  position: absolute;
  width: 150px;
  height: 150px;
  object-fit: scale-down;
  right: 10%;
}

@media (max-width: 550px) {
  .details-modal__product-image {
    width: 100px;
    height: 100px;
    right: 0;
  }
}

.details-modal .close {
  font-size: 30px;
}
