$pan-btn-disabled-color: #757575;
$pan-btn-disabled-background-color: #CBCBCB;
$pan-btn-default: var(--primary-color);
$pan-btn-default-hover: var(--primary-hover-color);
$pan-btn-default-pressed: var(--primary-pressed-color);
$pan-btn-danger: var(--danger-color);
$pan-btn-danger-hover: var(--danger-hover-color);
$pan-btn-danger-pressed: var(--danger-pressed-color);

$pan-font-family: Montserrat;

$pan-border-radius-base: 2px;

body {
  color: #303030
}


.affiliate-grid {
  display: grid;
  grid-template-columns:  3fr 1fr;
  grid-column-gap: 2%;
}

@media screen and (max-width: 940px){
  .affiliate-grid{
    grid-template-columns: 1fr;
    grid-row-gap: 3%;
  }
}