.datepicker-extract {
  display: flex;
  align-items: center;
}

.react-datepicker-wrapper {
  flex: 1;
}

.extract-list {
  background-color: #FFFFFF;
  margin-bottom: 30px;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  padding: 10px 15px;
}

.w-100 {
  width: 30% !important;
}

.extract-donwload-container {
  display: flex;  
  align-items: center;  
}



.extract-donwload-container h4 {
  margin: 0px;
  font-size: 16px;
}

.datepicker-input-custom {
  color: var(--primary-color);
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
}

.datepicker-extract
.Date-picker {
  border-radius: 5px;
  border: none;
  background-color: transparent;
  width: 100px;
}

.datepicker-extract
.react-datepicker {
  /* height: 100%;
  width: 100%;
  border: none;
  border-radius: 16px;
  border: 1px solid #E0E0E0; */
}

.datepicker-extract
.react-datepicker__month-container {
  /* width: 100%;
  height: 100%; */
}

.datepicker-extract
.react-datepicker__month {
  /* height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.datepicker-extract
.react-datepicker__month-wrapper {
  /* display: flex;
  justify-content: space-between;
  flex: 1; */
}

.datepicker-extract
.react-datepicker__month-text {
  /* flex: 1;
  font-size: 16px; */
}

.datepicker-extract
.react-datepicker__month--selected {
  /* background-color: var(--primary-color) !important; */
}

.datepicker-extract
.react-datepicker__header {
  /* height: 20% !important;
  font-size: 18px !important;  */
}

.extract-card-download-btn {
  font-family: var(--font-family), "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  color: #fff;
  padding: 4px 15px;
  border: 1px solid #E0E0E0;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);
  border-radius: 15px;
  background-color: var(--primary-color);
  transition-duration: 150ms;
  transition-timing-function: linear;
}

.extract-card-download-btn:hover {
  background-color: var(--primary-hover-color);
}

.extract-card-download-btn:active {
  background-color: var(--primary-pressed-color);
  box-shadow: none;
  transform: translateY(2px);
}


@media (max-width: 1500px) {
  .container {
      width: 700px;
  }
}

@media (max-width: 600px) {
  .container {
      width: 400px;
  }

  .extract-card-download-btn {
    padding: 2px 5px;
  }
}

@media (max-width: 1720px) {
  .datepicker-extract {
    width: 35%;
    margin-left: 50%;
  }
}

@media (max-width: 1306px) {
  .datepicker-extract {
    width: 50%;
  }
}

@media (max-width: 732px) {
  .datepicker-extract {
    width: 80%;
    margin-left: 20%;
  }
}

@media (max-width: 540px) {
  .extract-donwload-container {
    justify-content: center;
    display: block;
  }
}