.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 270px;
  display: block;
  z-index: 1;
  color: #fff;
  background: #414141;
  font-weight: 200;

  .sidebar-wrapper {
    background: #414141;
    position: relative;
    height: 100%;

    width: 270px;
    z-index: 4;
    padding-bottom: 30px;
    scrollbar-width: none;

    @media screen and (max-height: 1000px) {
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: rgba(255, 255, 255, 0.4) #414141;
      height: calc(100% - 150px);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 270px;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .logo {
    position: relative;
    z-index: 4;
    display: flex;
    width: 270px;
    background: #414141;
    flex-direction: column;
    justify-content: left;
    text-align: center;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    div.avatar-link {
      float: left;
      text-align: center;
      width: 60px;
      margin-right: 10px;
      border-radius: 50%;
      background: white;
      height: 60px;

      img {
        width: 98%;
        height: 98%;
        border-radius: 50%;
        display: block;
        padding: 2px;
      }

    }

    div.logo-link {
      float: left;
      text-align: center;
      width: 80px;
      margin-right: 10px;
      border-radius: 24px;

      img {
        width: 100%;
        min-height: 50px;
        height: auto;
        display: block;
        border-radius: 2px;
      }

      a.logo-normal {
        display: block;
      }
    }

    .logo-img {
      width: 34px;
      display: inline-block;
      height: 34px;
      margin-left: -2px;
      margin-top: -2px;
      margin-right: 10px;
      border-radius: 30px;
      text-align: center;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    li {
      >a {
        color: #ffffff;
        margin: 6px 0px;
        padding: 6px 15px;
        opacity: 0.8;
        display: flex;
        align-items: center;
      }

      &:hover>a,
      &.open>a,
      &.open>a:focus,
      &.open>a:hover {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      &.active>a {
        color: #ffffff;
        opacity: 1;
        background: rgba(203, 203, 210, 0.15);

        @media screen and (max-width: 990px) {
          &:after {
            content: "";
            position: absolute;
            height: 16px;
            width: 16px;
            left: 0;
            margin-left: -8px;
            background-color: #03a9f4;
          }

          @media screen and (max-height: 1000px) {
            &:after {
              content: none;
            }
          }
        }

        @media screen and (max-height: 1000px) {
          &:before {
            content: none;
          }
        }
      }

      >div {
        color: #ffffff;
        margin: 10px 0px;
        padding: 6px 15px;
        cursor: pointer;
        opacity: 0.8;
        display: flex;
        align-items: center;
      }

      &:hover>div,
      &.open>div,
      &.open>div:focus,
      &.open>div:hover {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      &.active>div {
        color: #ffffff;
        opacity: 1;
        background: rgba(203, 203, 210, 0.15);

        @media screen and (max-width: 990px) {
          &:after {
            content: "";
            position: absolute;
            height: 16px;
            width: 16px;
            left: 0;
            margin-left: -8px;
            background-color: #03a9f4;
          }

          @media screen and (max-height: 1000px) {
            &:after {
              content: none;
            }
          }
        }

        @media screen and (min-width: 991px) {
          &:before {
            content: var(--picto);
            position: absolute;
            height: 16px;
            width: 16px;
            right: 0;
            margin-right: -8px;
            background-color: #03a9f4;
          }

          @media screen and (max-height: 1000px) {
            &:before {
              content: none;
            }
          }
        }
      }
    }

    p {
      margin: 0;
      line-height: 26px;
      margin-left: 16px;
      font-size: 12px;
      font-weight: 600;

      @media screen and (max-height: 768px) {
        line-height: 14px;
      }

      @media screen and (max-height: 568px) {
        line-height: 8px;

      }

      @media screen and (max-height: 480px) {
        line-height: 1px;

      }
    }

    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}

.sidebar,
body>.navbar-collapse {
  .logo {
    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    opacity: 1;
  }

  &:after {
    background: none;
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }
}

.main-panel {
  background: #F5F5F5;
  position: relative;
  float: right;
  width: $sidebar-width;

  >.content {
    padding: 15px 15px;
    min-height: calc(100% - 123px);
  }

  >.footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
  }
}

.sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}