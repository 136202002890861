.btn{
    border-width: $border-thick;
    background-color: $transparent-bg;
    font-weight: $font-weight-normal;
    font-family: $pan-font-family;
    font-weight: 600;
    @include opacity(.95);
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: 16px;
    height: 48px;
    border-radius: 24px;
    @include btn-styles($default-color, $default-states-color);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &.btn-icon{
        padding: $padding-base-vertical;
    }

}

.btn-mlm-sm {
    border-width: $border-thick;
    background-color: $transparent-bg;
    font-weight: $font-weight-normal;
    font-family: $pan-font-family;
    font-weight: 600;
    @include opacity(.95);
    margin-right: 5px;
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: 14px;
    height: 36px;
    border-radius: 18px;
    @include btn-styles($default-color, $default-states-color);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &.btn-icon{
        padding: $padding-base-vertical;
    }
}

@media (max-width: 1440px) {
    .btn-small-extract {
        margin-top: 10px;
    }
}

.btn-mlm-secondary {
    border: 2px solid #414141 !important;
    font-size: 14px;
    font-weight: 600;
    color:#414141 !important;
    background-color: $transparent-bg;
    font-family: $pan-font-family;
    padding: $padding-base-vertical $padding-base-horizontal;
    height: 36px;
    min-width: 160px;
    @include btn-styles($default-color, $default-states-color);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &.btn-icon{
        padding: $padding-base-vertical;
    }

}



// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary { @include btn-styles($primary-color, $primary-states-color); }
.btn-success { @include btn-styles($success-color, $success-states-color); }
.btn-info    { @include btn-styles($info-color, $info-states-color); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
.btn-neutral {
    @include btn-styles($white-color, $white-color);

    &:active,
    &.active,
    .open > &.dropdown-toggle{
         background-color: $white-color;
         color: $default-color;
    }

    &.btn-fill,
    &.btn-fill:hover,
    &.btn-fill:focus{
        color: $default-color;
    }

    &.btn-simple:active,
    &.btn-simple.active{
        background-color: transparent;
    }
}

.btn-mlm {
    @include btn-styles($pan-btn-default, $pan-btn-default-hover);
    &:active{
        background-color: $pan-btn-default-pressed !important;
        border-color: $pan-btn-default-pressed !important;
    }
}

.btn-mlm-dark {
    color: #212121 !important;
    @include btn-styles(#FFF, #FAFAFA);
    background-color: #FCFCFC;
    &:active{
        background-color: $pan-btn-default-pressed !important;
    }
}

.btn-mlm-danger {
    @include btn-styles($pan-btn-danger, $pan-btn-danger-hover);
    &:active{
        background-color: $pan-btn-danger-pressed !important;
    }
    &:hover {
        background-color: $pan-btn-danger-hover !important;
    }
}

.btn-mlm-sm{
    &:disabled,
    &[disabled],
    &.disabled{
       font-size: 14px !important;
       text-align: center;
      //  line-height: 5px !important;
       padding: $padding-base-vertical $padding-base-horizontal;
       vertical-align: middle;
       height: 36px !important;
       border-radius: 18px;
       font-weight: 600;
   }
}


.btn{
     &:disabled,
     &[disabled],
     &.disabled{
        background-color: $pan-btn-disabled-background-color;
        color: $pan-btn-disabled-color;
        border-width: 0px;
        font-size: 16px;
        text-align: center;
        // line-height: 28px;
        font-family: var(--font-family);
        font-weight: 600;
        border-radius: 24px;
        height: 48px;
    }
}

.btn[disabled]:hover {
    color: #FFF;
    background-color: $pan-btn-disabled-background-color;
    border-color: $pan-btn-disabled-color;
}

.btn-round{
    height: 48px;
    border-width: $border-thin;
    border-radius: $btn-round-radius !important;
    padding: $padding-round-vertical $padding-round-horizontal;

    &.btn-icon{
        padding: $padding-round-vertical;
    }
}
.btn-simple{
    border: $none;
    font-size: $font-size-medium;
    padding: $padding-base-vertical $padding-base-horizontal;

    &.btn-icon{
        padding: $padding-base-vertical;
    }
}
.btn-lg{
   @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
   font-weight: $font-weight-normal;
}
.btn-sm{
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
.btn-xs {
    @include btn-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $border-radius-small);
}
.btn-wd {
    min-width: 140px;
}

.btn-group.select{
    width: 100%;
}
.btn-group.select .btn{
    text-align: left;
}
.btn-group.select .caret{
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

