.grid-parent {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 500px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.kv-form-login {
  grid-area: 1 / 5 / 2 / 7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  color: #000;
}

.kv-informative {
  grid-area: 1 / 1 / 2 / 5;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-color);
  .people {
    height: 80vh;
    max-width: 60vw;
  }
  .people-signin {
    object-fit: scale-down;
    height: 80vh;
    max-width: 60vw;
  }
}

.signup-title {
  width: 80%;
  margin: auto;
  margin-top: 16%;
  h3 {
    padding-right: 52%;
    color: #fff;
    font-weight: 800;
    margin-bottom: 50px;
  }
  h5{
    padding-right: 52%;
    color: #fff;
    font-weight: 600;
    margin-bottom: 50px;
  }
}
.signup-steps {
  margin: auto;
  margin-left: 10%;
  width: 88%;
}
.signup-step {
  display: flex;
  align-items: flex-start;
  height: 180px;
  margin-bottom: 4%;
  .signup-number {
    display: flex;
    align-items: center;
  }
  div > span {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin-right: -0.5%;
  }
  svg{
    margin-bottom: 100px;
  }
}

.signup-step-content {
  color: #fff;
  height: 100%;
  margin-left: 10%;
  width: 70%;
  h4 {
    font-weight: 800;
    margin: 0;
    margin-bottom: 2%;
  }
  p {
    color: #CBCBCB;
    font-weight: 500
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.no-absolut{
  border: none !important;
  position:  initial  !important;
}

.link-sign-in{
  position: absolute;
  color: #FFF;
  max-width: 15em;
  top: 47vh;
  padding-left: 2em;
  padding-top: 1em;
  font-weight: 700;
  font-size: 1em;
}

.signup-step-link {
  height: 30%;
  position: relative;
  margin-top: 20px;
  width: inherit;
  p{
    margin-top: 100px;
    color: #CBCBCB;
  }

  a {
    bottom: 0;
    position: absolute;
    font-weight: 700;
    color: #FFF;
    border-bottom: 1px solid #fff ;
  }
}

.primary-text-color{
  color: var(--primary-hover-color)
}

.login-pan-logo {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 40px;
  margin-left: 48px;
  height: 48px;
  z-index: 10;
}

.brand-logo {
  margin-top: 10vh;
  margin-bottom: 6vh;
  height: 64px;
  align-self: center;
}

.brand-logo-corporation {
  height: 256px;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .brand-logo-corporation {
    height: 124px;
    align-self: center;
  }
}

.brand-logo-corporation-container {
  height: 100vh;

  display: flex;
  justify-content: center;
  align-self: center;
}

.brand-logo-agx {
  margin-top: 15vh;
  margin-bottom: 10vh;
  height: 128px;
  align-self: center;
}

.section-name {
  color: var(--dark-color);
  font-weight: 800;
  margin-top: 25px;
  margin-bottom: 5px;
  text-align: left;

}

.two-inputs{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5%;
}

.small-inputs{
  display: grid;
  grid-template-columns: 1.5fr 4fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5%;
}

.fix-height{
  margin-bottom: -5px;
  max-height: 48px;
}

.signup-link {
  font-size: 14px;
  text-decoration: none;
  border-bottom: solid 1px var(--primary-pressed-color);
  margin: 8px;
  color: var(--primary-pressed-color);
  font-weight: 600;
  transition: opacity 0.3s;
  &:hover {
    color: var(--primary-pressed-color);
    text-decoration: none;
  }
  &:visited {
    color: var(--primary-pressed-color);
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.agx-link {
  @extend .signup-link;
  border: none;
  margin-bottom: 3vh;
}

.kv-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8%;
  text-align: center;
}
.login-elements {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-login {
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  background: var(--primary-color);
  border: none;
  border-radius: 12px;
  transition: all 0.1s;
  width: 100%;
  &:hover {
    color: #fff;
    background: var(--primary-hover-color);
    width: 100%;
  }
  &:focus {
    opacity: 1;
    color: #fff;
    background: var(--primary-pressed-color);
    border: 0;
    width: 100%;
  }
  &:active {
    opacity: 1;
    color: #fff;
    background: var(--primary-pressed-color);
    border: 0;
    width: 100%;
  }
  &:disabled {
    color: #757575;
    background: #e0e0e0 !important;
    width: 100%;
    border: none;
    border-radius: 12px;
    &:hover {
      color: #757575;
      border-radius: 12px;
      background: #e0e0e0;
    }
  }
}

.btn-signup {
  color: var(--primary-pressed-color);
  font-weight: 600;
  font-size: 16px;
  background: #fff;
  border: 2px solid var(--primary-pressed-color);
  margin-top: 3vh;
  border-radius: 12px;
  transition: background-color 300ms;
  width: 100%;
  a {
    color: #fafafa;
    font-weight: bold;
    font-size: 16px;
  }
  &:hover {
    color: var(--primary-pressed-color);
    border: 2px solid var(--primary-pressed-color);
    background: var(--primary-clear-color);
    width: 100%;
  }
  &:focus {
    color: #fff;
    border: 2px solid var(--primary-pressed-color);
    background: #00c5ff;
    border: 0;
    width: 100%;
  }
  &:disabled {
    color: #FFF;
    background-color: #CBCBCB;
    border-color: #757575;
    border-radius: 12px;
  }
}

.btn-whitout-account {
  color: black;
  font-weight: 600;
  font-size: 16px;
  background: #fff;
  border: 0;
  margin-top: 3vh;
  border-radius: 12px;
  transition: background-color 300ms;
  width: 100%;
  a {
    color: black;
    font-weight: bold;
    font-size: 16px;
  }
  &:hover {
    color: var(--primary-pressed-color);
    border: 2px solid 0;
    background: #dedede;
    width: 100%;
  }
  &:focus {
    color: #fff;
    border: 2px solid 0;
    background: #00c5ff;
    border: 0;
    width: 100%;
  }
  &:disabled {
    color: #FFF;
    background-color: #CBCBCB;
    border-color: #757575;
    border-radius: 12px;
  }
}

.btn-looks-disabled {
  color: #FFF;
  background-color: #CBCBCB;
  border-color: #757575;
  border-radius: 12px;
  &:hover {
    color: #FCFCFC;
    background-color: #CBCBCB;
    border-color: #757575;
  }
  &:focus {
    color: #fff;
    border: 0px solid var(--primary-pressed-color);
    background: #00c5ff;
    border: 0;
    width: 100%;
  }
}

.agx-btn-signup {
  border: none !important;
  &:hover {
    color: var(--primary-pressed-color);
    border: none !important;
    background: none !important;
    width: 100%;
  }
  &:focus {
    color: #fff;
    border: none !important;
    background: none !important;
    width: 100%;
  }
  &:disabled {
    color: #ff0;
    border: none !important;
    background: none !important;
    width: 100%;
  }
}

.signup-grid {
  position: absolute;
  width: 100%;

  min-height: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.signup-tips {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: 44px;
    top: -16px;
    transform: rotate(45deg);
    width: 1px;
    height: 119px;
    background: #616161;
  }
  &:after {
    content: "";
    position: absolute;
    left: 44px;
    bottom: -15px;
    transform: rotate(45deg);
    width: 1px;
    height: 119px;
    background: #616161;
  }
  background: #303030;
}

.Mui-disabled {
  background-color: #eee;
}
.Mui-disabled-label {
  padding: 2px;
  transform: translate(14px, -9px) scale(0.75) !important;
  border-radius: 4px;
  background: #eee;
}

@media screen and (max-width: 1366px){
  .signup-title {
    h3 {
      padding-right: 32%;
    }
    h5 {
      padding-right: 32%;
    }
  }
}

.signup-form-container {
  margin: 0 10%;
  height: 100%;
  text-align: center;
  padding: 10px;
}
.agreement-label{
  font-weight: 500;
  color: #616161;
  font-weight: 500;
  margin-top: -2px;
  font-family: 'montserrat';
}

.go-arrow {
  transform: rotate(90deg);
}

.signup-half-size {
  width: 50%;
  padding-right: 2.2%;
}

@media screen and (max-width: 940px) {
  .two-inputs {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 15px;
  }
  .small-inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 15px;
    & div:nth-of-type(1) {
      grid-area: 1 / 1 / 1 / 1;
    }
    & div:nth-of-type(2) {
      grid-area: 2 / 1 / 2 / span 2;
    }
  }
  .signup-half-size {
    width: 100%;
    padding-right: 0;
  }
  .signup-title{
    margin-top: 30%;
    width: 92%;

    h3 {
      padding-right: 0;
    }
  }
  .signup-steps {
    width: 92%;
    margin-left: auto;
  }
  .signup-step {
    div{
      span {
        font-size: 36px;
      }
    }
  }
  .signup-step-content {
    margin-left: 2%;
    width: auto;
     h4{
       font-size: 18px;
     }}
  .grid-parent,
  .signup-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .kv-informative {
    grid-area: 0;
    display: none;
  }
  .kv-form-login {
    grid-area: 1 / 1 / 3 / 2;
  }
  .margin-fix {
    margin-bottom: 15px;
  }
}
