html .user-voucher-report {
  margin: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.user-voucher-report__filters {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .user-voucher-report__filters > * {
    flex: 1 1 300px;
  }
}

.user-voucher-report__table {
  flex-grow: 1;
}
