
.logo-wrapper:hover {
  background-color: rgba(240, 240, 240, 0.05);
  .user-data-profile {
    animation-name: typewriter;
    animation-duration: 0.2s;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  .sidebar-container-user {
    animation-name: hoverOut;
    animation-duration: 0.15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  // .username-container {
  //   animation-name: hoverOut;
  //   animation-duration: 0.15s;
  //   animation-iteration-count: 1;
  //   animation-fill-mode: forwards;
  //   animation-timing-function: ease;
  // }
}

@keyframes typewriter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    letter-spacing: -5px;
  }
  80% {
    letter-spacing: 3px;
  }
  100% {
    letter-spacing: 1px;
    opacity: 1;
  }
}

@keyframes hoverOut {
  0% {
    opacity: 1;
  }
  50% {
    height: auto;
  }
  80% {
    width: 100%;
    letter-spacing: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
    letter-spacing: -5px;
    visibility: hidden;
  }
}
