.total-footer {
  background: white;
  margin-top: 1em;
  padding: 1em 1.5em;
  border-radius: 16px;
  color: 616161;
  font-family: 'Montserrat';
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.total-footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.total-footer__column {
  flex: 1 1 150px;
}

.total-footer__title {
  line-height: 1.75;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}

.total-footer p {
  margin: 0;
  color: #414141;
}

.total-footer__column-header {
  font-weight: 600;
  color: #414141;
}