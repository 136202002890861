
.proposal-status-voucher-buttons {
  margin: 0 15px 15px 15px ;
  display: flex;
  gap: 1em;
  max-width: 500px;
}

.proposal-status-voucher-buttons > button {
  flex: 1 1 0;
}

.proposal-status__sub-title {
  font-size: 1.5rem;
  margin: 0 15px 10px 15px;
}

.proposal-status-filters {
  display: flex;
  gap: .5em;
  flex-direction: column;
  margin: 0 15px;
}

.proposal-status-filters__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em;
}

.proposal-status-filters__column {
  text-align: left;
}

.proposal-status-filters__button {
  margin-top: .5em;
  width: 100%;
  margin-left: auto;
}

@media (min-width: 640px) {
  .proposal-status-filters__button  {
    max-width: calc(100% / 3 - 1em);
  }

  .voucher-report-modal-wrapper {
    height: calc(100vh - 30px);
  }
}

.voucher-report-modal {
  width: 100%;
}

.voucher-report-modal-wrapper {
  height: calc(100vh - 180px);
}

body .proposal-status-filter-badge {
  width: fit-content;
  font-weight: bold;
  max-width: 200px;
  padding: .05em .5em;
  text-transform: uppercase;
}
